import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import { AppContext } from "../../Contexts/AppContext";
import Loading from "./../../Components/Loading/Loading";
import getAllContents, { IGetAllContentsArgs, IContentsList } from "../../API/getAllContents";

import HorizontalList from "../../Components/HorizontalList";
import { motion } from "framer-motion";

function MoviesList(props: any) {
  const {
    state: { activeMediaType, activeLanguages },
  } = useContext(AppContext);

  const args: IGetAllContentsArgs = {
    endpoint: `${activeMediaType}/${props.name}`,
    activeLanguages,
  };

  const { isLoading, isError, data, error } = useQuery<IContentsList[]>(
    [`${activeMediaType}/${props.name}`, Object.values(args)],
    () => getAllContents(args)
  );

  const animation = {
    variants: {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
      slideStart: { clipPath: "inset(0 0% 0 100% round 8px)" },
      slideEnd: { clipPath: "inset(0 0 0 0% round 8px)" },
    },
    initial: ["hidden", "slideStart"],
    whileInView: ["visible", "slideEnd"],
    exit: ["hidden", "slideStart"],
    viewport: {
      amount: 0.4,
    },
    transition: {
      type: "spring",
      bounce: 0,
      duration: 1.5,
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <>Something went wrong! {error}</>;
  }

  return (
    <motion.div
      initial={animation.initial}
      whileInView={animation.whileInView}
      exit={animation.exit}
      viewport={animation.viewport}
      className="motion-box"
      transition={animation.transition}
    >
      <motion.div variants={animation.variants}>
        <HorizontalList displayName={props.displayName} data={data} />
      </motion.div>
    </motion.div>
  );
}

export default MoviesList;
